import React from "react";
import '../assets/styles/Footer.scss';
import logo from '../assets/images/logo.jpeg'; // Ajusta la ruta a tu logo

// Definir las props que Footer va a recibir
interface FooterProps {
  onLinkClick: (section: string) => void;
  
}

const Footer: React.FC<FooterProps> = ({ onLinkClick }) => {
  return (
    <div>
      <footer className="about-section2">
        <div className="image-wrapper">
          <img src={logo} alt="Avatar" />
          <h4>Plataforma Online De LATINCOINS</h4>
        </div>
        <div className="footer-center">
          <h2>Enlaces rápidos</h2>
          <ul className="footer-links">
            <li><a href="#1" onClick={() => onLinkClick("sagrilaft")}>SAGRILAFT</a></li>
            <li><a href="#2" onClick={() => onLinkClick("política-antilavado")}>Política AntiLavado</a></li>
            <li><a href="#3" onClick={() => onLinkClick("política-privacidad")}>Política De Privacidad</a></li>
            <li><a href="#4" onClick={() => onLinkClick("terminos-p2p")}>Términos y Condiciones P2P</a></li>
          </ul>
        </div>
        <div className="footer-right">
          <h2>Contacto</h2>
          <p>Puerto Asís, Colombia</p>
          <p>Correo: comercio@latincoins.com.co</p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
