import React from "react";
import '../assets/styles/Project.scss';

function Project() {
    return(
    <div>
        {}
    </div>
    );
}

export default Project;